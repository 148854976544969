const factorials = [1,1,2,6,24,120,720,5040,40320, 362880];

const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  }

  

const GetPermutationFromIndex= (numberOfVariables, permutationIndex) => {
    let remainingIndeces = [];
    let result = [];
  
    for(let i = 0; i < numberOfVariables; i++) {
      remainingIndeces[i]=i;
    }
  
    let resultIndex = 0;
    for (let factorialIndex = numberOfVariables - 1; factorialIndex > 1; factorialIndex--) {
      let curIndex = Math.floor(permutationIndex / factorials[factorialIndex]);
      result[resultIndex] = remainingIndeces[curIndex];
      remainingIndeces.splice(curIndex, 1);
      permutationIndex = permutationIndex % factorials[factorialIndex];
      resultIndex++;
    }
  
    if(permutationIndex % 2 === 0){
      result[resultIndex] = remainingIndeces[0];
      result[resultIndex + 1 ] = remainingIndeces[1]
    }else{
      result[resultIndex] = remainingIndeces[1]
      result[resultIndex + 1] = remainingIndeces[0]
    }
    
     return result;
  }

export {getRandomInt, GetPermutationFromIndex, factorials}