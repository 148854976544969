import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import questionImage from '../resources/icons/_.png'
import image1 from '../resources/icons/1.png'
import image2 from '../resources/icons/2.png'
import image3 from '../resources/icons/3.png'
import image4 from '../resources/icons/4.png'
import image5 from '../resources/icons/5.png'
import image6 from '../resources/icons/6.png'
import image7 from '../resources/icons/7.png'
import image8 from '../resources/icons/8.png'
import image9 from '../resources/icons/9.png'
import neutralImage from '../resources/icons/Neutral.png'
import redUnknownImage from '../resources/icons/Red_Unknown.png'
import greenUnknownImage from '../resources/icons/Green_Unknown.png'
import blueUnknownImage from '../resources/icons/Blue_Unknown.png'
import tempDown from '../resources/icons/Temp_Down.png';
import tempUp from '../resources/icons/Temp_Up.png';
import cauldronReset from '../resources/icons/Cauldron_Reset.png';

import {
    Button,
    Grid
} from '@mui/material';
import { styled } from '@mui/system';
import { GetPotionFormula } from '../utils/alchemicals';

const Paper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

function Cook({alchemicals}) {
    const [alchemicalSlot1, setAlchemicalSlot1] = useState(questionImage);
    const [alchemicalSlot2, setAlchemicalSlot2] = useState(questionImage);
    const [alchemicalIndex1, setAlchemicalIndex1] = useState(-1);
    const [alchemicalIndex2, setAlchemicalIndex2] = useState(-1);
    const [cooked, setCooked] = useState(false);
    const [potionResult, setPotionResult] = useState('');
    const [potionConsequence, setPotionConsequence] = useState('');
    const navigate = useNavigate();

    var imageMap = {
        1: image1,
        2: image2,
        3: image3,
        4: image4,
        5: image5,
        6: image6,
        7: image7,
        8: image8,
        9: image9
    }

    var potionImageMap = {
        0: neutralImage,
        1: redUnknownImage,
        2: greenUnknownImage,
        3: blueUnknownImage
    }

    var consequenceImageMap = {
        0: cauldronReset,
        1: tempUp,
        2: '',
        3: tempDown
    }

    const navigateBack = () => {
        navigate('/game');
    }

    // const setIndex1Event = (event) => {
    //     let index = event.target.value;
    //     if (index < 0 || index > 8)
    //         return;
    //     setAlchemicalIndex1(index);
    // }

    // const setIndex2Event = (event) => {
    //     let index = event.target.value;
    //     if (index < 0 || index > 8)
    //         return;
    //     setAlchemicalIndex2(index);
    // }

    const cookEvent = () => {
        console.log('Cooked');
        console.log(`alch 1: ${alchemicalIndex1}`);
        console.log(`alch 2: ${alchemicalIndex2}`);
        let potion = GetPotionFormula(alchemicalIndex1, alchemicalIndex2);
        console.log(potion);
        setPotionResult(potionImageMap[potion[0]]);
        setPotionConsequence(consequenceImageMap[potion[0]]);
        setCooked(true);

    }

    const setAlchemical = (index) => {
        if (alchemicalSlot1 === questionImage) {
            setAlchemicalSlot1(imageMap[index + 1]);
            setAlchemicalIndex1(alchemicals[index]);
        } else if (alchemicalSlot2 === questionImage) {
            setAlchemicalSlot2(imageMap[index + 1]);
            setAlchemicalIndex2(alchemicals[index]);
        }
    }

  return (
    <div>
        <Paper>
            <h1>Cook a Potion</h1>
            <Button
                onClick={navigateBack}>
                    Back
            </Button>

            { cooked ? 
            
             <div>
                <img src={potionResult} alt=""/>
                <img src={potionConsequence} alt=""/>
             </div>
             :
             <div></div>
            }

            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid></Grid>
                <Grid>
                    <Button onClick={() => setAlchemicalSlot1(questionImage)}>
                        <img src={alchemicalSlot1} alt=""/>
                    </Button>
                    +
                    <Button onClick={() => setAlchemicalSlot2(questionImage)}>
                        <img src={alchemicalSlot2} alt=""/>
                    </Button>
                </Grid>
                <Grid></Grid>
            </Grid>

            { cooked ? 
            <div></div>
             : 
            <div>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    { Array.from(Array(9)).map((_, index) => (
                        <Grid item xs={2} sm={4} md={4} key={index}>
                            <Button onClick={() => setAlchemical(index)}>
                                <img src={require(`../resources/icons/${index + 1}.png`)} alt=""/>
                            </Button>
                        </Grid>
                    ))}
                </Grid>
                
                <Button
                    onClick={ cookEvent }>
                        Cook
                    </Button>
            </div> }
        </Paper>
    </div>
  )
}

export default Cook;
