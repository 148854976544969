import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Button
} from '@mui/material';
import { styled } from '@mui/system'
import { factorials, getRandomInt } from '../utils/math';
import { GetAlchemicals } from '../utils/alchemicals';
import { CodeToAlphaCode } from '../utils/gameCode';

const Paper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
})

function Home({setAlchemicals, setGameCode}) {
    const navigate = useNavigate();

    const handleNewGame = () =>
    {
        // generate the thing and store it
        // generate random number between 0 and 9! - 1
        let code = getRandomInt(factorials[9]);
        // setGameCode
        setGameCode(CodeToAlphaCode(code));
        // setAlchemicals
        let x = GetAlchemicals(code);
        console.log(x);
        setAlchemicals(GetAlchemicals(code));
        navigate('/game');
    }

    const handleContinue = () => 
    {
        navigate('/continue');
    }

  return (
    <div>
        <Paper>
            <h1>Alchemist Rush</h1>
            <Button
                onClick={handleNewGame}>
                New Game
            </Button>
            
            <Button
                onClick={handleContinue}>
                Continue Game
            </Button>
        </Paper>
    </div>
  )
}

export default Home;
