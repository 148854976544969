import React, {useState} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Home from './pages/home';
import Continue from './pages/continue';
import Game from './pages/game';
import Cook from './pages/cook';
import TestOnSelf from './pages/testSelf';
import Sell from './pages/sell';

function App() {
  const [gameCode, setGameCode] = useState('');
  const [alchemicals, setAlchemicals] = useState('');

  return (
    <div>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home setAlchemicals={setAlchemicals} setGameCode={setGameCode} />} />
          <Route exact path='/continue' element={<Continue setAlchemicals={setAlchemicals} setGameCode={setGameCode} />} />
          <Route exact path='/game' element={<Game alchemicals={alchemicals} setAlchemicals={setAlchemicals} gameCode={gameCode} setGameCode={setGameCode} />} />
          <Route exact path='/cook' element={<Cook alchemicals={alchemicals} />} />
          <Route exact path='/testOnSelf' element={<TestOnSelf alchemicals={alchemicals} />} /> 
          <Route exact path='/testOnStudent' element={<TestOnSelf alchemicals={alchemicals} />} />
          <Route exact path='/sell' element={<Sell alchemicals={alchemicals}/>} />
        </Routes>
      </Router>
    </div>
  )
}

// home
// continue
// game
// cook
// test-self
// test-student
// other?


export default App;
