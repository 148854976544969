import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Button,
    TextField
} from '@mui/material';
import { styled } from '@mui/system';

import { GetAlchemicals } from '../utils/alchemicals';
import { AlphaCodeToCode, CheckAlphabetCode } from '../utils/gameCode';

const Paper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
})

function Continue({setAlchemicals, setGameCode}) {
    const [alphaCode, setAlphaCode] = useState('');
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();

    const navigateBack = () => {
        navigate('/');
    }
    
    const handleSubmit = () => {
        // Check for accurate code type
        if (!CheckAlphabetCode(alphaCode)) {
            // Error message
            let newErrors = {
                ...errors,
                code: 'Incorrect game code'
            };
            setErrors(newErrors);
            return;
        }
        // Set the game variables
        // setGameCode
        setGameCode(alphaCode);
        // setAlchemicals
        let code = AlphaCodeToCode(alphaCode);
        let x = GetAlchemicals(code);
        console.log(x);
        setAlchemicals(x);
        navigate('/game');
    }

    const setAlphaCodeEvent = (event) => {
        let code = event.target.value;
        setAlphaCode(code.toUpperCase());
    }

  return (
    <div>
        <Paper>
            <h1>Alchemist Rush</h1>
            <Button
                onClick={navigateBack}>
                    Back
                </Button>
            
            <TextField
                            id="gameCode"
                            name="gameCode"
                            label="Game Code"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            autoFocus
                            autoComplete="neverAutoComplete"
                            helperText={errors.email}
                            error={errors.email ? true : false}
                            onChange={ setAlphaCodeEvent }
                        />
            
            <Button
                onClick={handleSubmit}>
                Go
            </Button>
        </Paper>
    </div>
  )
}

export default Continue;
