import { GetPermutationFromIndex } from "./math";

const GetAlchemicals = (code) => {
    return GetPermutationFromIndex(9, code);
}

const GetAlchemical = (alchemicalIndex) => {
    return {
        red: {
            size: GetRedSize(alchemicalIndex),
            polarity: GetRedPolarity(alchemicalIndex)
        },
        green: {
            size: GetGreenSize(alchemicalIndex),
            polarity: GetGreenPolarity(alchemicalIndex)
        },
        blue: {
            size: GetBlueSize(alchemicalIndex),
            polarity: GetBluePolarity(alchemicalIndex)
        }
    }
}

const GetRedSize = (index) => {
    switch (index) {
        case 0:
        case 1:
        case 6:
        case 7:
            return 1;
        default:
            return 0;
    }
}

const GetGreenSize = (index) => {
    switch (index) {
        case 2:
        case 3:
        case 6:
        case 7:
            return 1;
        default:
            return 0;
    }
}

const GetBlueSize = (index) => {
    switch (index) {
        case 4:
        case 5:
        case 6:
        case 7:
            return 1;
        default:
            return 0;
    }
}

const GetRedPolarity = (index) => {
    switch (index) {
        case 0:
        case 3:
        case 4:
        case 6:
            return 1;
        case 8:
            return 0;
        default:
            return -1;
    }
}

const GetGreenPolarity = (index) => {
    switch (index) {
        case 0:
        case 2:
        case 5:
        case 6:
            return 1;
        case 8:
            return 0;
        default:
            return -1;
    }
}

const GetBluePolarity = (index) => {
    switch (index) {
        case 1:
        case 2:
        case 4:
        case 6:
            return 1;
        case 8:
            return 0;
        default:
            return -1;
    }
}

const GetPotionFormula = (index1, index2) => {
    if (index1 > index2) {
        let temp = index1;
        index1 = index2;
        index2 = temp;
    }
    let alchemical1 = GetAlchemical(index1);
    let alchemical2 = GetAlchemical(index2);

    // All the cases for the neutral alchemical
    if (index2 === 8) {
        if (index1 === 6 || index1 === 7)
            return [0, 0];
        if (alchemical1.red.size === 1)
            return [1, 0];
        if (alchemical1.green.size === 1)
            return [2, 0];
        if (alchemical1.blue.size === 1)
            return [3, 0];
    }

    if (alchemical1.red.size === alchemical2.red.size && 
            alchemical1.green.size === alchemical2.green.size &&
            alchemical1.blue.size === alchemical2.blue.size)
        return [0, 0];

    // Cases for other alchemicals
    if (alchemical1.red.size !== alchemical2.red.size) {
        if (alchemical1.red.polarity === alchemical2.red.polarity) {
            return [1, alchemical1.red.polarity];
        }
    }

    if (alchemical1.green.size !== alchemical2.green.size) {
        if (alchemical1.green.polarity === alchemical2.green.polarity) {
            return [2, alchemical1.green.polarity];
        }
    }

    if (alchemical1.blue.size !== alchemical2.blue.size) {
        if (alchemical1.blue.polarity === alchemical2.blue.polarity) {
            return [3, alchemical1.blue.polarity];
        }
    }

    return [0, 0];
}

export {
    GetAlchemicals,
    GetPotionFormula
}