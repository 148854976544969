const codeOffset = 10000;
const asciiOffset = 65;

const CheckAlphabetCode = (alphaCode) => {
    alphaCode = alphaCode.toUpperCase();
    if (alphaCode.length !== 4) {
        return false;
    }

    for (let i = 0; i < 4; i++) {
        let curLetterInt = alphaCode.charCodeAt(i) - asciiOffset;
        if (curLetterInt < 0 || curLetterInt > 25)
            return false;
    }

    return true;
}

const CodeToAlphaCode = (code) => {
    let trueCode = code + codeOffset;
    let alphaCode = '';
    for (let i = 0; i < 4; i++) {
        let oppInt = 4 - i - 1;
        let diviser = Math.pow(26, oppInt);
        let charCode = Math.floor(trueCode / diviser);
        trueCode -= charCode * diviser;
        let char = String.fromCharCode(asciiOffset + charCode);
        alphaCode = alphaCode + char;
    }
    return alphaCode;
}

const AlphaCodeToCode = (alphaCode) => {
  alphaCode = alphaCode.toUpperCase();
  let code = 0;
  for (let i = 0; i < 4; i++) {
    let charCode = alphaCode.charCodeAt(i) - asciiOffset;
    let oppInt = 4 - i - 1;
    let multiplier = Math.pow(26, oppInt);
    let codeAddition = multiplier * charCode;
    code += codeAddition;
  }
  return code - codeOffset;
}

export {
    CheckAlphabetCode,
    CodeToAlphaCode,
    AlphaCodeToCode,
    codeOffset
}