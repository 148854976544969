import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import questionImage from '../resources/icons/_.png'
import image1 from '../resources/icons/1.png'
import image2 from '../resources/icons/2.png'
import image3 from '../resources/icons/3.png'
import image4 from '../resources/icons/4.png'
import image5 from '../resources/icons/5.png'
import image6 from '../resources/icons/6.png'
import image7 from '../resources/icons/7.png'
import image8 from '../resources/icons/8.png'
import image9 from '../resources/icons/9.png'
import neutralImage from '../resources/icons/Neutral.png'
import redUnknownImage from '../resources/icons/Red_Unknown.png'
import greenUnknownImage from '../resources/icons/Green_Unknown.png'
import blueUnknownImage from '../resources/icons/Blue_Unknown.png'
import redNeg from '../resources/icons/Red_Negative.png'
import redNeut from '../resources/icons/Red_Neutral.png'
import redPos from '../resources/icons/Red_Positive.png'
import greenNeg from '../resources/icons/Green_Negative.png'
import greenNeut from '../resources/icons/Green_Neutral.png'
import greenPos from '../resources/icons/Green_Positive.png'
import blueNeg from '../resources/icons/Blue_Negative.png'
import blueNeut from '../resources/icons/Blue_Neutral.png'
import bluePos from '../resources/icons/Blue_Positive.png'

import {
    Button,
    Grid
} from '@mui/material';
import { styled } from '@mui/system';
import { GetPotionFormula } from '../utils/alchemicals';

const Paper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

function Sell({alchemicals}) {
    const [requestedPotion1, setRequestedPotion1] = useState('');
    const [requestedPotion1Image, setRequestedPotion1Image] = useState('');
    const [requestedPotion2, setRequestedPotion2] = useState('');
    const [requestedPotion2Image, setRequestedPotion2Image] = useState('');
    const [potion1AlchemicalSlot1, setPotion1AlchemicalSlot1] = useState(questionImage);
    const [potion1AlchemicalSlot2, setPotion1AlchemicalSlot2] = useState(questionImage);
    const [potion1AlchemicalIndex1, setPotion1AlchemicalIndex1] = useState(-1);
    const [potion1AlchemicalIndex2, setPotion1AlchemicalIndex2] = useState(-1);
    const [potion2AlchemicalSlot1, setPotion2AlchemicalSlot1] = useState(questionImage);
    const [potion2AlchemicalSlot2, setPotion2AlchemicalSlot2] = useState(questionImage);
    const [potion2AlchemicalIndex1, setPotion2AlchemicalIndex1] = useState(-1);
    const [potion2AlchemicalIndex2, setPotion2AlchemicalIndex2] = useState(-1);
    // const [cooked, setCooked] = useState(false);
    const [potionResult, setPotionResult] = useState(0);
    const [sellState, setSellState] = useState(0);
    // const [potionConsequence, setPotionConsequence] = useState('');
    const navigate = useNavigate();

    var imageMap = {
        1: image1,
        2: image2,
        3: image3,
        4: image4,
        5: image5,
        6: image6,
        7: image7,
        8: image8,
        9: image9
    }

    var potionImageMap = {
        0: redPos,
        1: redNeg,
        2: greenPos,
        3: greenNeg,
        4: bluePos,
        5: blueNeg
    }

    const navigateBack = () => {
        if (sellState == 0) {
            navigate('/game');
            return;
        }
        setSellState(sellState - 1);
    }

    // const cookEvent = () => {
    //     console.log('Cooked');
    //     console.log(`alch 1: ${alchemicalIndex1}`);
    //     console.log(`alch 2: ${alchemicalIndex2}`);
    //     let potion = GetPotionFormula(alchemicalIndex1, alchemicalIndex2);
    //     console.log(potion);
    //     // setRequestedPotion1(potionImageMap[potion[0]][potion[1]]);
    //     setCooked(true);

    // }

    const setPotion1Alchemical = (index) => {
        if (potion1AlchemicalSlot1 === questionImage) {
            setPotion1AlchemicalSlot1(imageMap[index + 1]);
            setPotion1AlchemicalIndex1(alchemicals[index]);
        } else if (potion1AlchemicalSlot2 === questionImage) {
            setPotion1AlchemicalSlot2(imageMap[index + 1]);
            setPotion1AlchemicalIndex2(alchemicals[index]);
        }
    }

    const setPotion2Alchemical = (index) => {
        if (potion2AlchemicalSlot1 === questionImage) {
            setPotion2AlchemicalSlot1(imageMap[index + 1]);
            setPotion2AlchemicalIndex1(alchemicals[index]);
        } else if (potion2AlchemicalSlot2 === questionImage) {
            setPotion2AlchemicalSlot2(imageMap[index + 1]);
            setPotion2AlchemicalIndex2(alchemicals[index]);
        }
    }

    const setPotion1 = (index) => {
        setRequestedPotion1(getPotion(index));
        setRequestedPotion1Image(potionImageMap[index]);
        setSellState(1);
    }

    const setPotion2 = (index) => {
        setRequestedPotion2(getPotion(index));
        setRequestedPotion2Image(potionImageMap[index]);
        setSellState(3);
    }

    const getPotion = (index) => {
        switch (index) {
            case 0:
            default:
                return [1, 1];
            case 1:
                return [1, -1];
            case 2:
                return [2, 1];
            case 3:
                return [2, -1];
            case 4:
                return [3, 1];
            case 5:
                return [3, -1];
        }
    }

    const goToNext = () => {
        setSellState(2);
    }

    const goToResults = () => {
        let potion1 = GetPotionFormula(potion1AlchemicalIndex1, potion1AlchemicalIndex2);
        let potion2 = GetPotionFormula(potion2AlchemicalIndex1, potion2AlchemicalIndex2);
        let correct = 0;
        console.log(potion1);
        console.log('?=')
        console.log(requestedPotion1);
        console.log((potion1[0] === requestedPotion1[0] && potion1[1] === requestedPotion1[1]) ? 1 : 0);
        console.log(potion2);
        console.log('?=')
        console.log(requestedPotion2);
        console.log((potion2[0] === requestedPotion2[0] && potion2[1] === requestedPotion2[1]) ? 1 : 0);
        correct += (potion1[0] === requestedPotion1[0] && potion1[1] === requestedPotion1[1]) ? 1 : 0;
        correct += (potion2[0] === requestedPotion2[0] && potion2[1] === requestedPotion2[1]) ? 1 : 0;
        setPotionResult(correct);
        setSellState(4);
    }

    const returnToGame = () => {
        navigate('/game');
    }

    const renderSwitch = (state) => {
        switch(state) {
            case 0:
                return (
                    <div>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {Array.from(Array(6)).map((_, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                    <Button onClick={() => setPotion1(index)}>
                                        <img src={potionImageMap[index]} alt=""/>
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                )
            case 1:
                return (
                    <div>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                
                            <Grid></Grid>
                            <Grid>
                                <img src={requestedPotion1Image} alt="" />
                                <Button onClick={() => setPotion1AlchemicalSlot1(questionImage)}>
                                    <img src={potion1AlchemicalSlot1} alt=""/>
                                </Button>
                                +
                                <Button onClick={() => setPotion1AlchemicalSlot2(questionImage)}>
                                    <img src={potion1AlchemicalSlot2} alt=""/>
                                </Button>
                            </Grid>
                            <Grid></Grid>
                        </Grid>
        
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {Array.from(Array(9)).map((_, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                    <Button onClick={() => setPotion1Alchemical(index)}>
                                         <img src={require(`../resources/icons/${index + 1}.png`)} alt=""/>
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                        
                        <Button
                            onClick={ goToNext }>
                                Next
                            </Button>
                    </div>
                )
            case 2:
                return (
                    <div>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {Array.from(Array(6)).map((_, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                    <Button onClick={() => setPotion2(index)}>
                                        <img src={potionImageMap[index]} alt=""/>
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                )
            case 3:
                return (
                    <div>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                
                            <Grid></Grid>
                            <Grid>
                                <img src={requestedPotion1Image} alt="" />
                                <img src={potion1AlchemicalSlot1} alt=""/>
                                +
                                <img src={potion1AlchemicalSlot2} alt=""/>
                            </Grid>
                            <Grid></Grid>
                        </Grid>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                
                            <Grid></Grid>
                            <Grid>
                                <img src={requestedPotion2Image} alt="" />
                                <Button onClick={() => setPotion2AlchemicalSlot1(questionImage)}>
                                    <img src={potion2AlchemicalSlot1} alt=""/>
                                </Button>
                                +
                                <Button onClick={() => setPotion2AlchemicalSlot2(questionImage)}>
                                    <img src={potion2AlchemicalSlot2} alt=""/>
                                </Button>
                            </Grid>
                            <Grid></Grid>
                        </Grid>
        
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {Array.from(Array(9)).map((_, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                    <Button onClick={() => setPotion2Alchemical(index)}>
                                         <img src={require(`../resources/icons/${index + 1}.png`)} alt=""/>
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                        
                        <Button
                            onClick={ goToResults }>
                                Next
                            </Button>
                    </div>
                )
            case 4:
                return (
                    <div>
                        {potionResult} Correct
                        <Button
                            onClick={ returnToGame }>
                                Finish
                            </Button>
                    </div>
                )
            default:
                return (
                    <div>
                        What?
                    </div>
                )
        }
    }

  return (
    <div>
        <Paper>
            <h1>Sell Potions</h1>
            {sellState < 4 ? 
            <Button
                onClick={navigateBack}>
                    Back
            </Button> :
            <div></div>
            }

            {renderSwitch(sellState)}
        </Paper>
    </div>
  )
}

export default Sell;
