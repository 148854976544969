import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Button,
    TextField
} from '@mui/material';
import { styled } from '@mui/system';

import { CheckAlphabetCode, CodeToAlphaCode, GetAlchemicals, SetAlchemicals } from '../utils/alchemicals';

const Paper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
})

function Game({alchemicals, setAlchemicals, gameCode, setGameCode}) {
    const navigate = useNavigate();

    const navigateBack = () => {
        setAlchemicals([]);
        setGameCode('');
        navigate('/');
    }

  return (
    <div>
        <Paper>
            <h1>Alchemist Rush</h1>
            <Button
                onClick={navigateBack}>
                    Exit Game
                </Button>

            {gameCode}
            
            <Button
                onClick={() => {navigate('/cook')}}>
                Cook
            </Button>
            
            <Button
                onClick={() => {navigate('/testOnSelf')}}>
                Test on Self
            </Button>
            
            <Button
                onClick={() => {navigate('/testOnSelf')}}>
                Test on Student
            </Button>
            
            <Button
                onClick={() => {navigate('/sell')}}>
                Sell to Adventurer
            </Button>
        </Paper>
    </div>
  )
}

export default Game;
